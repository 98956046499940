import React, { useContext, useEffect, useState } from 'react';
import { FaUser } from 'react-icons/fa';
import { HiLogout } from 'react-icons/hi';
import { VscTriangleDown } from 'react-icons/vsc';
import { CgProfile } from 'react-icons/cg';
import Calendar from './Calendar';
import Notification from './Notification';
import translator from '../../assets/json/translator.json';
import { Get } from '../../services/apiCall';
import base64 from 'base-64';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  AuthContext,
  TimelineContext,
  GeneralContext,
} from '../../services/context/context';
import Styles from './index.module.css';
import MenuIcon from '@mui/icons-material/Menu';
import styledComponents from 'styled-components';
import QMarkHover from '../../assets/images/QMarkHover.svg';
import { Tooltip as ReactTooltip } from 'react-tooltip';


const Navbar = (props) => {
  const [name, setName] = useState(null);
  const {
    language,
    handleLanguage,
    unmountNS,
    selectedUnit,
    selectedVillageTankerValue,
    handleUnitChange,
    handleTankerValueChange
  } = useContext(GeneralContext);
  const { token } = useContext(AuthContext);
  const { selectedSeason, handleSeasonClick} = useContext(TimelineContext);

  const villageId =
          window.location.pathname.startsWith('/analytics') ||
          window.location.pathname.startsWith('/plan') ||
          window.location.pathname.startsWith('/advisory')
            ? window.location.pathname.split('/')[7]
            : localStorage.getItem(base64.encode('defaultProgramId'));

  const languages = [
    {
      lang: 'English',
      value: 'en',
    },
    {
      lang: 'हिन्दी',
      value: 'hi',
    },
    {
      lang: 'मराठी',
      value: 'mr',
    },
    {
      lang: 'ગુજરાતી',
      value: 'gu',
    },
    {
      lang: 'తెలుగు',
      value: 'te',
    },
  ];

  const seasons = [
    {
      season: 'Rabi',
      value: 'Rabi',
    },
    {
      season: 'Summer',
      value: 'Summer',
    },
  ];

  const units = [
    {
      unit: 'TCM',
      value: 'TCM',
    },
    {
      unit: 'Cr Litre',
      value: 'Cr Litre',
    },
    {
      unit: 'Tankers',
      value: 'Tankers',
    },
  ];

  /**
   *
   * This function will be called after:-
   * 1. mounting
   * 2. on language change
   */
  useEffect(() => {
    const initialFunction = async () => {
      const params = {
        language: language,
      };
      const response = await Get('/info/user', token, params);

      if (response.status === 'success') {
        setName(response.data.name);
      } else {
        setName(localStorage.getItem(base64.encode('name')));
      }
    };

    initialFunction();

    return () => {
      setName(localStorage.getItem(base64.encode('name')));
    };
  }, [language, token]);


  // call on every village change 
  useEffect(()=>{
    const getUnit = async ()=>{
      const data = await Get(`/info/village/units?villageId=${villageId}`);
      if(data?.status === 'success'){
        handleTankerValueChange(data?.data[0]?.amount_in_litre);
      }
    }
    getUnit();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[villageId])



  /**
   *
   * @description
   * expands the list in the navbar when clicked
   */
  const handleUserClick = () => {
    document.getElementById('userDropdown').classList.toggle(Styles.show);
  };
  /**
   *
   * @description
   * when logout button clicked, remove personal local storage and redirect
   * page to home
   */
  const handleLogoutClick = async () => {
    localStorage.clear();
    window.location.href = '/';
  };
  /**
   *
   * @param {*} event - click event of the language dropdown
   * @description
   * runs a props function to change the state language in component App
   */
  const changeLanguage = (event) => {
    handleLanguage(event.target.value);
  };
  /**
   *
   * @param {*} head - state pageHead of the component App
   * @description
   * this function splits the pageHead into two words which helps in translation
   * @example Home --> होमपेज and Untiya : Surfacewater --> उन्तिया : भू-पृष्ठ जल
   * @returns words in desired language
   */
  const getPageHead = () => {
    if (
      window.location.pathname.split('/')[1].startsWith('program') ||
      window.location.pathname === '/'
    ) {
      return translator[language]['Home'];
    }
    if (window.location.pathname.split('/')[1].startsWith('cluster')) {
      return translator[language]['Cluster'];
    }
    if (
      window.location.pathname.split('/')[1].startsWith('analytics-overview')
    ) {
      return translator[language]['Analytics'];
    }
    if (
      window.location.pathname.split('/')[1].startsWith('analytics-groundwater')
    ) {
      return `${translator[language]['Analytics']} : ${translator[language]['Groundwater']}`;
    }
    if (
      window.location.pathname
        .split('/')[1]
        .startsWith('analytics-surfacewater')
    ) {
      return `${translator[language]['Analytics']} : ${translator[language]['Surfacewater']}`;
    }
    if (
      window.location.pathname.split('/')[1].startsWith('analytics-landuse')
    ) {
      return `${translator[language]['Analytics']} : ${translator[language]['Landuse']}`;
    }
    if (window.location.pathname.startsWith('/plan')) {
      return translator[language]['Plan'];
    }
    if (window.location.pathname.startsWith('/advisory')) {
      return translator[language]['Advisory'];
    }
  };

  /**
   *
   * @condition
   * if unmountNS is true show nothing
   * */
  return unmountNS ? (
    ''
  ) : (
    <>
      <div
        className={Styles.navbar}
        style={{
          transition: '0.5s ease',
          filter: `${props.isSidebarOpen ? 'blur(2px)' : 'blur(0)'}`,
        }}
      >
        <div className={Styles.navbarContainer}>
          <div className="row">
            {window.innerWidth <= 768 && (
              <div
                className={Styles.menuIconContainer}
                onClick={() => {
                  props.disableScroll();
                  props.setIsSidebarOpen(!props.isSidebarOpen);
                  document.addEventListener(
                    'mousedown',
                    props.handleOutsideClick,
                  );
                }}
              >
                <MenuIcon id={Styles.menuIcon} />
              </div>
            )}
            <div className={Styles.user} onClick={() => handleUserClick()}>
              <FaUser className={Styles.userIcon} />
              <VscTriangleDown className={Styles.triangleDownIcon} />
              <div className={Styles.username}>
                {translator[language]['Hello']} <b>{name?.split(' ')[0]}</b>
                {window.innerWidth <= 768 && <VscTriangleDown />}
              </div>
            </div>
            <div id={'userDropdown'} className={Styles.userDropdownContent}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '2%',
                }}
              >
                {<CgProfile style={{ marginRight: '3%' }} />}{' '}
                {translator[language]['Profile']}
              </div>
              <div
                onClick={() => handleLogoutClick()}
                style={{ display: 'flex', alignItems: 'center', padding: '2%' }}
              >
                {<HiLogout style={{ marginRight: '3%' }} />}{' '}
                {translator[language]['Logout']}
              </div>
            </div>

            <Wrapper>
              <div className={Styles.language}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {(window.location.pathname.startsWith('/plan') ||
                    window.location.pathname.startsWith('/advisory')) && (
                    <div className={Styles['plan-for-season-wrapper']}>
                      <div
                        className={Styles['plan-for-season']}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {!window.location.pathname.startsWith('/advisory') && (
                          <p
                            className={Styles.planForSeasonMobile}
                            style={{ color: 'rgb(56, 131, 230)', margin: '0' }}
                          >
                            {translator[language]['Plan for Season']}
                          </p>
                        )}
                        <div style={{ marginLeft: '20px' }}>
                          <FormControl
                            sx={{
                              minWidth: window.innerWidth >= 768 && 120,
                            }}
                            size="small"
                          >
                            <Select
                              labelId="Season Plan"
                              id={Styles.language}
                              value={selectedSeason}
                              defaultValue={'Rabi'}
                              onChange={(e) => {
                                handleSeasonClick(e.target.value);
                              }}
                              className={Styles.languageOptions}
                            >
                              {seasons?.map((e) => {
                                return (
                                  <MenuItem
                                    key={e.value}
                                    value={e.value}
                                    style={{
                                      minHeight:
                                        window.innerWidth < 768 && '22px',
                                      fontSize:
                                        window.innerWidth < 768 && '12px',
                                    }}
                                  >
                                    {translator[language][e.season]}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* ADDING A UNIT CHANGER  */}
                  {(window.innerWidth > 768 ||
                    (!window.location.pathname.startsWith('/plan') &&
                      !window.location.pathname.startsWith('/advisory'))) &&  villageId !== localStorage.getItem(base64.encode('defaultProgramId')) && (
                    <>
                      <FormControl
                        className={Styles.webUnitChangeIsVisible}
                        sx={{
                          minWidth: window.innerWidth >= 768 && 120,
                          marginRight: '10px',
                        }}
                        size="small"
                      >
                        <Select
                          labelId="language"
                          id="language"
                          value={selectedUnit}
                          onChange={(e) => {
                            handleUnitChange(e.target.value);
                          }}
                          className={Styles.languageOptions}
                        >
                          {units.map((e) => {
                            return (
                              <MenuItem
                                key={e.value}
                                value={e.value}
                                style={{
                                  minHeight: window.innerWidth < 768 && '22px',
                                  fontSize: window.innerWidth < 768 && '12px',
                                }}
                              >
                                 {window.innerWidth <= 768 ? e.unit.slice(0,3) :translator[language][e.unit]}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <span
                        style={{
                          marginLeft: '-8px',
                          marginRight: '7px',
                          marginTop: '-3px',
                        }}
                      >
                        <img
                          data-tooltip-id="my-tooltip-navbar"
                          onClick={() => navigator.vibrate(100)}
                          src={QMarkHover}
                          height="12px"
                          width="12px"
                          alt="tooltip"
                          style={{ cursor: 'pointer', marginTop: '7px' }}
                        />
                      </span>
                    </>
                  )}

                  {/* ADDING A UNIT CHANGER  */}

                  <FormControl
                    sx={{ minWidth: window.innerWidth >= 768 && 120 }}
                    size="small"
                  >
                    <Select
                      labelId="language"
                      id="language"
                      value={language}
                      onChange={changeLanguage}
                      className={Styles.languageOptions}
                    >
                      {languages.map((e) => {
                        return (
                          <MenuItem
                            key={e.value}
                            value={e.value}
                            style={{
                              minHeight: window.innerWidth < 768 && '22px',
                              fontSize: window.innerWidth < 768 && '12px',
                            }}
                          >
                            {window.innerWidth <= 768
                              ? e.lang.slice(0, 2)
                              : e.lang}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </Wrapper>
            <Notification />
          </div>
          <div className="row">
            <div className={Styles.homeHeading}>
              {getPageHead()}
            </div>
            {(window.location.pathname.startsWith('/plan') ||
              window.location.pathname.startsWith('/advisory')) && (
              <div className={Styles.mobileUnitChangeIsVisible}>
                <FormControl
                  sx={{
                    minWidth: window.innerWidth >= 768 && 120,
                    marginRight: '10px',
                    border: 'none',
                  }}
                  size="small"
                >
                  <Select
                    labelId="language"
                    id="language"
                    value={selectedUnit}
                    onChange={(e) => {
                      handleUnitChange(e.target.value);
                    }}
                    className={Styles.languageOptions}
                    sx={{
                      border: 'none',
                      height: '1.6rem',
                    }}
                  >
                    {units.map((e) => {
                      return (
                        <MenuItem
                          key={e.value}
                          value={e.value}
                          style={{
                            minHeight: '22px',
                            fontSize: '12px',
                          }}
                        >
                          {window.innerWidth <= 768 ? e.unit.slice(0,3) :translator[language][e.unit]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <span
                  style={{
                    marginLeft: '-6px',
                    marginRight: '7px',
                    marginTop: '-3px',
                  }}
                >
                  <img
                    data-tooltip-id="my-tooltip-navbar"
                    onClick={() => navigator.vibrate(100)}
                    src={QMarkHover}
                    height="12px"
                    width="12px"
                    alt="tooltip"
                    style={{ cursor: 'pointer', marginTop: '7px' }}
                  />
                </span>
              </div>
            )}

            <Calendar />
          </div>
        </div>
      </div>

      {/* TOOLTIP FOR UNIT CONVERSIONS INFORMATION STARTS*/}
      <ReactTooltip
        id="my-tooltip-navbar"
        place="bottom"
        style={{
          background: 'rgb(154, 154, 154);',
          color: 'white',
          fontSize: window.innerWidth < 768 ? '10px' : '12px',
          zIndex: 999,
        }}
        content={
          /* 1 TCM = 0.1 Crore Litre
             1 Cr Litre = 10^7 Litres
             1 Tanker = selectedVillageTankerValue(in TCM) / 10^7
               for example:- current tanker value is 5000.
                             5000/10^7 = 0.0005 Crore Litres     
           */
          <div>
            <p>1 {translator[language]['TCM']} = 0.1 {translator[language]['Cr Litre']}</p>
            <p>
              1 {translator[language]['Cr Litre']} = 10<sup>7</sup> {translator[language]['Litre']}
            </p>
            <p>1 {translator[language]['Tanker']} = {selectedVillageTankerValue/10000000} {translator[language]['Cr Litre']}</p>
          </div>
        }
      />
      {/* TOOLTIP FOR UNIT CONVERSIONS INFORMATION ENDS*/}
    </>
  );
};

export default Navbar;

const Wrapper = styledComponents.div`

@media screen and (max-width:768px){
  .MuiOutlinedInput-notchedOutline{
    border:none;
  }
}

`;
