import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import Login from './pages/Login';
import Sidebar from './components/Sidebar/index';
import Home from './pages/Home';
import Navbar from './components/Navbar/index';
import Taluka from './pages/Taluka';
import AnalyticsOverview from './pages/AnalyticsOverview';
import AnalyticsLandUse from './pages/AnalyticsLandUse';
import AnalyticsSurfacewater from './pages/AnalyticsSurfacewater';
import AnalyticsGroundwater from './pages/AnalyticsGroundwater';

import Message from './pages/Message';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import base64 from 'base-64';
import { Get } from './services/apiCall';
import LoginLoader from './components/Loader/climb';
import Plan from './pages/Plan';
import AdvisoryPreview from './components/Plan/AdvisoryPreview';
import {
  AuthContext,
  TimelineContext,
  GeneralContext,
} from './services/context/context';

const App = () => {
  /**
   *
   * @component App
   * @states
   * username ---> Stores the username of the dashboard's user
   * authToken ---> Stores the authentication token of the user will helps him/her to login and as well call the APIs
   * selectedMonth ---> Stores the month for which we want the data in our dashboard
   * selectedYear ---> Stores the year for which we want the data in our dashboard
   * defaultMonth ---> Stores the lastest month which can be shown
   * defaultYear ---> Stores the lastest year which can be shown
   * language ---> Stores the current language of the app
   * width ---> Stores the current width of the dashboard window
   * unmountNS ---> If true we have to unmount Navbar and Sidebar
   * showSeason ---> If true show season instead of months on the Navbar
   * selectedSeason ---> Stores the current season to show on the planning page
   */

  let currentDate = new Date();
  /**
   *
   * Setting the current date to last date of the previous month
   * @example 16/09/21 to 30/08/21
   * Web dashboard will show data till previous month that's why we
   * set the current date to last date of the previous month
   */
  currentDate.setDate(0);

  const [username, setUsername] = useState('');
  const [emailId, setEmailId] = useState('');
  const [accountType, setAccountType] = useState('');
  const [authToken, setAuthToken] = useState('');
  const [name, setName] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(currentDate.getMonth());
  const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear());
  const defaultMonth = currentDate.getMonth();
  const defaultYear = currentDate.getFullYear();
  const [hasURLChanged, setHasURLChanged] = useState('');
  const [language, setLanguage] = useState(
    localStorage.getItem(base64.encode('language'))
      ? localStorage.getItem(base64.encode('language'))
      : 'en',
  );
  const [width, setWidth] = useState(window.innerWidth);
  const [unmountNS, setUnmountNS] = useState(false);
  const [showSeason, setShowSeason] = useState(false);
  const [selectedSeason, setSelectedSeason] = useState('Rabi');
  const [selectedUnit, setSelectedUnit] = useState('TCM');
  const [selectedVillageTankerValue, setSelectedVillageTankerValue] =
    useState('');
  const [baseSelectedPlanMonth, setBaseSelectedPlanMonth] = useState('');
  const [pageHead, setPageHead] = useState('');
  const [programId, setProgramId] = useState(
    window.location.pathname.startsWith('/programId')
      ? window.location.pathname.split('/')[2]
      : window.location.pathname.startsWith('/cluster') ||
        window.location.pathname.startsWith('/analytics') ||
        window.location.pathname.startsWith('/plan')
      ? window.location.pathname.split('/')[3]
      : localStorage.getItem(base64.encode('defaultProgramId')),
  );
  const [selectedProgram, setSelectedProgram] = useState('');
  const [urlChangedLoader, setURLChangedLoader] = useState(false);
  const [currentPlanYear, setCurrentPlanYear] = useState('');
  const [organizationOnboardedDate, setOrganizationOnboardedDate] =
    useState('');
  const [villageArea, setVillageArea] = useState('');

  const [organizationEndDate, setOrganizationEndDate] = useState('');
  const updateDimensionsRef = useRef();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleVillageAreaChange = (area) => {
    setVillageArea(area);
  };

  const sidebarRef = useRef();

  const disableScroll = () => {
    let scrollTop = window.scrollY || document.documentElement.scrollTop;
    let scrollLeft = window.scrollX || document.documentElement.scrollLeft;

    window.onscroll = () => {
      window.scrollTo(scrollLeft, scrollTop);
    };
  };

  const enableScroll = () => {
    window.onscroll = null;
  };

  const handleOutsideClick = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsSidebarOpen(false);
      document.removeEventListener('mousedown', handleOutsideClick);
      enableScroll();
    }
  };

  useEffect(() => {
    /**
     *
     * Adding an event listener on the window resize so that
     * whenever the screen width is less than 1200px
     * a small screen alert will be shown
     * @todo make the screen responsive until 800px
     */
    const handleResize = () => {
      updateDimensionsRef.current();
    };
    window.addEventListener('resize', handleResize);
    /**
     * If the user was previously logged-in there should be a authToken
     * in their local storage.
     */
    if (localStorage.getItem(base64.encode('authToken'))) {
      setAuthToken(localStorage.getItem(base64.encode('authToken')));
      setUsername(localStorage.getItem(base64.encode('username')));
      setEmailId(localStorage.getItem(base64.encode('emailId')));
      setAccountType(localStorage.getItem(base64.encode('accountType')));
      setName(localStorage.getItem(base64.encode('name')));
      setSelectedProgram(
        localStorage.getItem(base64.encode('defaultProgramName')),
      );
    }

    handleURLChange(window.history);
    handleUrlManuallyChanges();

    return () => {
      document.removeEventListener('click', handleOutsideClick);
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * UseCallback here is used to memoize the updateDimensions function. It
   * ensures that the function is only recreated if the 'width' dependency changes.
   */
  const updateDimensions = useCallback(() => {
    if (window.innerWidth < 850 || (width < 850 && window.innerWidth > 850)) {
      setWidth(window.innerWidth);
    }
  }, [width]);

  useEffect(() => {
    updateDimensionsRef.current = updateDimensions;
  }, [updateDimensions]);

  const handlePlanYear = (year) => {
    setCurrentPlanYear(year);
  };

  const handleOrganizationOnboardedDate = (
    currentOrganizationStart,
    currentOrganizationEnd,
  ) => {
    setOrganizationOnboardedDate(currentOrganizationStart);
    setOrganizationEndDate(currentOrganizationEnd);
  };

  const handleBaseSelectedPlanMonth = (selectedPlanMonth) => {
    setBaseSelectedPlanMonth(selectedPlanMonth);
  };

  const handleUrlManuallyChanges = async (id) => {
    if (localStorage.getItem(base64.encode('authToken'))) {
      const token = localStorage.getItem(base64.encode('authToken'));

      const programIdLocal = id ? id : parseInt(programId);

      let currentPathName = window.location.pathname;

      if (
        currentPathName.includes('programId') &&
        currentPathName.includes('clusterId') &&
        currentPathName.includes('villageId')
      ) {
        setURLChangedLoader(true);

        const clusterId =
          window.location.pathname.startsWith('/cluster') ||
          window.location.pathname.startsWith('/analytics') ||
          window.location.pathname.startsWith('/plan') ||
          window.location.pathname.startsWith('/advisory')
            ? window.location.pathname.split('/')[5]
            : localStorage.getItem(base64.encode('defaultProgramId'));

        const villageId =
          window.location.pathname.startsWith('/cluster') ||
          window.location.pathname.startsWith('/analytics') ||
          window.location.pathname.startsWith('/plan') ||
          window.location.pathname.startsWith('/advisory')
            ? window.location.pathname.split('/')[7]
            : localStorage.getItem(base64.encode('defaultProgramId'));

        const payload = {
          programId: programIdLocal,
          clusterId: clusterId,
          villageId: villageId,
        };

        const progId = await Get('/info/pr-cl-vl-access', token, payload);

        if (progId.status !== 'success') {
          window.location.href = '/notfound';
        } else {
          setURLChangedLoader(false);
        }
      } else if (
        currentPathName.includes('programId') &&
        currentPathName.includes('clusterId')
      ) {
        setURLChangedLoader(true);
        const clusterId =
          window.location.pathname.startsWith('/cluster') ||
          window.location.pathname.startsWith('/analytics')
            ? window.location.pathname.split('/')[5]
            : localStorage.getItem(base64.encode('defaultProgramId'));

        const payload = {
          programId: programIdLocal,
          clusterId: clusterId,
        };
        const progId = await Get('/info/pr-cl-access', token, payload);

        if (progId.status !== 'success') {
          window.location.href = '/notfound';
        } else {
          setURLChangedLoader(false);
        }
      } else if (currentPathName.includes('programId')) {
        setURLChangedLoader(true);

        const payload = {
          programId: programIdLocal,
        };
        const progId = await Get('/info/pr-access', token, payload);

        if (progId.status !== 'success') {
          window.location.href = '/notfound';
        } else {
          setURLChangedLoader(false);
        }
      }
    }
  };

  const handleURLChange = async (history) => {
    var pushState = history.pushState;
    history.pushState = function (state) {
      hasURLChanged ? setHasURLChanged(false) : setHasURLChanged(true);
      return pushState.apply(history, arguments);
    };
  };

  const handleUser = (authToken, username, name, emailId, accountType) => {
    setAuthToken(authToken);
    setUsername(username);
    setName(name);
    setEmailId(emailId);
    setAccountType(accountType);
  };
  const handleMonth = (month) => {
    setSelectedMonth(month);
  };
  const handleYear = (year) => {
    setSelectedYear(year);
  };
  const handleLanguage = (language) => {
    setLanguage(language);
  };

  const handleSeason = (flag) => {
    setShowSeason(flag);
  };

  const handleSeasonClick = (season) => {
    setSelectedSeason(season);
  };

  const handleUnitChange = (unit) => {
    setSelectedUnit(unit);
  };

  const handleTankerValueChange = (tankerValue) => {
    setSelectedVillageTankerValue(tankerValue);
  };

  const unmount = (flag) => {
    setUnmountNS(flag);
  };

  useEffect(() => {
    setURLChangedLoader(false);
  }, [programId]);

  // FOR CHANGING THE CURRENT PROGRAM ID
  const handleProgramIdChange = async (progId, programName) => {
    window.history.replaceState(
      null,
      'Midas by Ekatvam',
      `/programId/${progId}`,
    );

    setURLChangedLoader(true);
    setProgramId(progId);
    setSelectedUnit('TCM');
    if (programName !== undefined) {
      setSelectedProgram(programName);
    }
  };

  const handlePageHead = (pageHead) => {
    setPageHead(pageHead);
  };

  const changeLangauge = (language) => {
    setLanguage(language);
  };

  const parseJwt = (token) => {
    var base64Url = token && token.split('.')[1];
    var _base64 = base64Url && base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      base64
        .decode(_base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  };

  // if screen is smaller than 850px - Mobile screens added

  // if the user does not have a authentication token
  // then show then the login page

  if (!authToken) {
    return (
      <div className="App">
        <Login
          token={authToken}
          handleUser={handleUser}
          handleProgramIdChange={handleProgramIdChange}
          changeLangauge={changeLangauge}
          handleUrlManuallyChanges={handleUrlManuallyChanges}
        />
      </div>
    );
  } else {
    // if the token expires.. logout
    try {
      const jwtPayload = parseJwt(authToken);
      if (Date.now() - jwtPayload.exp * 1000 > 0) {
        window.location.href = '/';
        localStorage.clear();
      }
    } catch (e) {
      window.location.href = '/';
      localStorage.clear();
    }
  }
  /**
   *
   * if user has an authentication token or he/she
   * has successfully logged-in
   */

  // Contexts defined below:-
  const AuthContextValues = {
    username: username,
    emailId: emailId,
    accountType: accountType,
    token: authToken,
    name: name,
  };

  const TimelineContextValues = {
    month: selectedMonth,
    year: selectedYear,
    selectedSeason: selectedSeason,
    pageHead: pageHead,
    defaultMonth: defaultMonth,
    defaultYear: defaultYear,
    hasURLChanged: hasURLChanged,
    showSeason: showSeason,
    currentPlanYear: currentPlanYear,
    organizationOnboardedDate: organizationOnboardedDate,
    organizationEndDate: organizationEndDate,
    baseSelectedPlanMonth: baseSelectedPlanMonth,
    // Functions
    handlePageHead: handlePageHead,
    handleSeason: handleSeason,
    handleMonth: handleMonth,
    handleYear: handleYear,
    handleSeasonClick: handleSeasonClick,
    handlePlanYear: handlePlanYear,
    handleOrganizationOnboardedDate: handleOrganizationOnboardedDate,
    handleBaseSelectedPlanMonth: handleBaseSelectedPlanMonth,
  };

  const GeneralContextValues = {
    programId: programId,
    selectedProgram: selectedProgram,
    unmountNS: unmountNS,
    language: language,
    selectedUnit: selectedUnit,
    selectedVillageTankerValue: selectedVillageTankerValue,
    villageArea: villageArea,
    // Functions
    handleUser: handleUser,
    handleLanguage: handleLanguage,
    unmount: unmount,
    changeLangauge: changeLangauge,
    handleProgramIdChange: handleProgramIdChange,
    handleUnitChange: handleUnitChange,
    handleTankerValueChange: handleTankerValueChange,
    handleVillageAreaChange: handleVillageAreaChange,
  };

  return (
    <HelmetProvider>
      {/** for seo */}
      <Helmet>
        <title>Midas by Ekatvam</title>
        <meta
          name="description"
          content="A water management web dashboard by Ekatvam Innovations that allows the authorities to understand the water related conditions of villages"
        />
      </Helmet>

      {/** * * For all the pages Navbar and Sidebar will be common * therefore
        it is shifted out of the Switch tag therefore * for all the pages Navbar
        and Sidebar will be present */
      /** * * unmount={unmountNS}{' '}
        state is passed to Navbar and * Sidebar because when we are rendering
        the NotFound page, * we don't want Navbar and Sidebar to show up * So
        when these type of page are rendering we change the unmount variable *
        state to false so that we can unmount Navbar and Sidebar * @todo It
        works for now but we have to find a better way to unmount these *
        components while showing NotFound page */}
      {urlChangedLoader ? (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <LoginLoader />
        </div>
      ) : (
        !urlChangedLoader &&
        programId && (
          <Router>
            <AuthContext.Provider value={AuthContextValues}>
              <TimelineContext.Provider value={TimelineContextValues}>
                <GeneralContext.Provider value={GeneralContextValues}>
                  <Navbar
                    disableScroll={disableScroll}
                    handleOutsideClick={handleOutsideClick}
                    isSidebarOpen={isSidebarOpen}
                    setIsSidebarOpen={setIsSidebarOpen}
                  />
                  <Sidebar
                    enableScroll={enableScroll}
                    handleOutsideClick={handleOutsideClick}
                    sidebarRef={sidebarRef}
                    isSidebarOpen={isSidebarOpen}
                    setIsSidebarOpen={setIsSidebarOpen}
                  />
                  <div
                    style={{
                      transition: '0.5s ease',
                      filter: `${isSidebarOpen ? 'blur(2px)' : 'blur(0)'}`,
                    }}
                  >
                    <Routes>
                      {/** Home page https://ekatvam-midas.com/ */}

                      <Route
                        path="/"
                        element={<Navigate to={`/programId/${programId}`} />}
                      />

                      <Route
                        path={`/programId/:programId`}
                        element={<Home />}
                      />
                      {/** Taluka page https://ekatvam-midas.com/taluka/3849/Rajula/analytics */}
                      <Route
                        path="/cluster/programId/:programId/clusterId/:clusterId"
                        exact
                        element={<Taluka />}
                      />

                      {/** Analytics page https://ekatvam-midas.com/village/515963/Untiya/analytics/overview */}
                      <Route
                        path="/analytics-overview/programId/:programId/clusterId/:clusterId/villageId/:villageId"
                        exact
                        element={<AnalyticsOverview />}
                      />
                      {/** Landuse page https://ekatvam-midas.com/village/515963/Untiya/analytics/landuse */}

                      <Route
                        path="/analytics-landuse/programId/:programId/clusterId/:clusterId/villageId/:villageId"
                        exact
                        element={<AnalyticsLandUse />}
                      />

                      {/** Surfacewater page https://ekatvam-midas.com/village/515963/Untiya/analytics/surfacewater */}

                      <Route
                        path="/analytics-surfacewater/programId/:programId/clusterId/:clusterId/villageId/:villageId"
                        exact
                        element={<AnalyticsSurfacewater />}
                      />

                      {/** Groundwater page https://ekatvam-midas.com/village/515963/Untiya/analytics/groundwater */}

                      <Route
                        path="/analytics-groundwater/programId/:programId/clusterId/:clusterId/villageId/:villageId"
                        exact
                        element={<AnalyticsGroundwater />}
                      />

                      {/** Plan page https://ekatvam-midas.com/village/515963/Untiya/plan */}

                      <Route
                        path="/plan/programId/:programId/clusterId/:clusterId/villageId/:villageId"
                        exact
                        element={<Plan />}
                      />

                      <Route
                        path="/advisory/programId/:programId/clusterId/:clusterId/villageId/:villageId"
                        exact
                        element={<AdvisoryPreview />}
                      />

                      {/** We give the unmount function control to the NotFound component*/}
                      <Route
                        path="*"
                        element={
                          <Message
                            gif={'https://i.postimg.cc/vBNy6WrZ/404.gif'}
                            unmount={unmount}
                            head={'This page is gone.'}
                            description={
                              "...maybe the page you're looking for is not found or never existed."
                            }
                            showHome={true}
                            programId={programId}
                          />
                        }
                      />
                    </Routes>
                  </div>
                </GeneralContext.Provider>
              </TimelineContext.Provider>
            </AuthContext.Provider>
          </Router>
        )
      )}
    </HelmetProvider>
  );
};

export default App;
